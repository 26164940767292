import React from 'react';
import { styled } from '@glitz/react';
import Input from 'Shared/Fields/Text';
import { pixelsToUnit, thin } from 'Shared/Style';
import { show, hide } from 'Shared/Icon/Eye';
import { alto, vividtangerine } from 'Shared/Style/colors';
import { ErrorMessage } from 'Shared/FeedbackMessage';

type PropType = {
  name?: string;
  inputLabel?: string;
  inputPlaceholder?: string;
  minLength?: number;
  inputValue: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  error?: string[] | string;
};

type StateType = {
  showPassword: boolean;
};

export default class ResetPassword extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);

    this.state = {
      showPassword: false,
    };
  }

  onEyeIconClick = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    const showEyeIcon = <ShowEyeIcon />;
    const hideEyeIcon = <HideEyeIcon />;
    return (
      <InputContainer>
        <InputLabel>{this.props.inputLabel}</InputLabel>
        <InputWithEyeWrapper>
          <InputWrapper>
            <InputField
              name={!!this.props.name ? this.props.name : 'password'}
              onChange={this.props.onChange}
              required
              type={this.state.showPassword ? 'text' : 'password'}
              value={this.props.inputValue}
              minLength={!!this.props.minLength ? this.props.minLength : 5}
              placeholder={this.props.inputPlaceholder}
              invalid={!!this.props.error}
            />
          </InputWrapper>
          <IconContainer
            css={!!this.props.error && { border: { xy: { color: vividtangerine } } }}
            onClick={this.onEyeIconClick}
          >
            {this.state.showPassword ? showEyeIcon : hideEyeIcon}
          </IconContainer>
        </InputWithEyeWrapper>
        {!!this.props.error && <Error>{this.props.error}</Error>}
      </InputContainer>
    );
  }
}

const InputField = styled(Input, {
  borderRadius: 0,
  paddingLeft: pixelsToUnit(16),
});

const InputLabel = styled.label({
  marginLeft: pixelsToUnit(16),
});

const InputContainer = styled.div({
  minHeight: pixelsToUnit(112),
});

const InputWithEyeWrapper = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const eyeIconStyle = styled({
  color: '#a4a4a4',
  fontWeight: 'bold',
  width: pixelsToUnit(25),
  height: pixelsToUnit(25),
  alignItems: 'center',
});

const HideEyeIcon = eyeIconStyle(hide);

const ShowEyeIcon = eyeIconStyle(show);

const IconContainer = styled.span({
  backgroundColor: '#F6F6F6',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  display: 'flex',
  height: pixelsToUnit(50),
  marginLeft: pixelsToUnit(-1),
  right: pixelsToUnit(5),
  width: '10%',
  minWidth: pixelsToUnit(52),
  zIndex: 2,
  border: {
    xy: {
      width: thin,
      style: 'solid',
      color: alto,
    },
    left: {
      width: 0,
    },
  },
});

const InputWrapper = styled.div({
  width: '90%',
});

const Error = styled(ErrorMessage, {
  marginTop: 0,
  paddingLeft: pixelsToUnit(16),
});
