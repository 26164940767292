/**
 * @ComponentFor RegisterBlock
 */
import React from 'react';
import { epiPropertyValue } from '@avensia/scope-episerver';
import Register from './Register';
import RegisterPropType from './RegisterBlock.type';

type PropType = RegisterPropType;

const RegisterBlock = (props: PropType) => (
  <Register
    sectionHeader={epiPropertyValue(props.sectionHeader)}
    usernameLabel={epiPropertyValue(props.usernameLabel)}
    usernamePlaceholder={epiPropertyValue(props.usernamePlaceholder)}
    emailLabel={epiPropertyValue(props.emailLabel)}
    emailPlaceholder={epiPropertyValue(props.emailPlaceholder)}
    passwordLabel={epiPropertyValue(props.passwordLabel)}
    passwordPlaceholder={epiPropertyValue(props.passwordPlaceholder)}
    repeatPasswordLabel={epiPropertyValue(props.repeatPasswordLabel)}
    repeatPasswordPlaceholder={epiPropertyValue(props.repeatPasswordPlaceholder)}
    firstnameLabel={epiPropertyValue(props.firstnameLabel)}
    firstnamePlaceholder={epiPropertyValue(props.firstnamePlaceholder)}
    lastnameLabel={epiPropertyValue(props.lastnameLabel)}
    lastnamePlaceholder={epiPropertyValue(props.lastnamePlaceholder)}
    successMessage={epiPropertyValue(props.successMessage)}
    successRedirectPage={epiPropertyValue(props.successRedirectPage).url}
    registrationTitle={epiPropertyValue(props.registrationTitle)}
    registerButtonCaption={epiPropertyValue(props.registerButtonCaption)}
    newsletterLabel={epiPropertyValue(props.newsletterLabel)}
    newsletterLinkLabel={epiPropertyValue(props.newsletterLinkLabel)}
    newsletterText={epiPropertyValue(props.newsletterText)}
    termsLabel={epiPropertyValue(props.termsLabel)}
    termsLinkLabel={epiPropertyValue(props.termsLinkLabel)}
    termsText={epiPropertyValue(props.termsText)}
    loginRedirectPage={epiPropertyValue(props.loginRedirectPage).url}
    loginButtonCaption={epiPropertyValue(props.loginButtonCaption)}
  />
);

export default RegisterBlock;
