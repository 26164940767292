import React from 'react';
import { styled } from '@glitz/react';
import Check from 'Shared/Icon/Check-Success';

type PropType = {
  message: string;
};

export default (props: PropType) => {
  return (
    <Content>
      {props.message}
      <Check />
    </Content>
  );
};

const Content = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
