import React from 'react';
import { postJson, pushState, translate } from '@avensia/scope';
import { styled } from '@glitz/react';
import { connectWithFeedback, ConnectPropType } from 'Shared/Button/Feedback';
import RegisterViewModelType from './RegisterViewModel.type';
import Input from 'Shared/Fields/Text';
import { Checkbox } from 'Shared/Fields/Toggle';
import { ErrorMessage } from 'Shared/FeedbackMessage';
import { pixelsToUnit, minMediumMediaQuery } from 'Shared/Style';
import AccountForm from './../Form/AccountForm';
import AccountPanel from './../Panel/AccountPanel';
import Html from 'Shared/Partials/PropertyTypes/Html';
import PasswordVisibility from 'Shared/PasswordVisibility';
import SuccessMessage from 'Shared/SuccessMessage';
import MainContainer from 'Shared/MainContainer';
import Panel from 'Shared/Panel';
import CloseIcon from 'Shared/Icon/Close';
import { emailPattern } from 'Shared/email-pattern';
import { passwordPattern } from 'Shared/password-pattern';

type RequiredPropType = {
  sectionHeader: string;
  usernameLabel: string;
  usernamePlaceholder: string;
  emailLabel: string;
  emailPlaceholder: string;
  passwordLabel: string;
  passwordPlaceholder: string;
  repeatPasswordLabel: string;
  repeatPasswordPlaceholder: string;
  firstnameLabel: string;
  firstnamePlaceholder: string;
  lastnameLabel: string;
  lastnamePlaceholder: string;
  successMessage: string;
  successRedirectPage: string;
  registrationTitle: string;
  registerButtonCaption: string;
  newsletterLabel: string;
  newsletterLinkLabel: string;
  newsletterText: Scope.XhtmlString;
  termsLabel: string;
  termsLinkLabel: string;
  termsText: Scope.XhtmlString;
  loginRedirectPage: string;
  loginButtonCaption: string;
};

type ConnectedPropType = RequiredPropType & ConnectPropType;

type StateType = {
  username: string;
  email: string;
  password: string;
  repeatPassword: string;
  firstName?: string;
  lastName?: string;
  showPassword: boolean;
  errors: { [item: string]: string[] };
  receiveNewsletter: boolean;
  agreeTerms: boolean;
  isSuccess: boolean;
  isNewsletterOpen: boolean;
  isTermsOpen: boolean;
  message?: string;
};

const InitialErrors: any = {
  username: null,
  email: null,
  password: null,
  repeatPassword: null,
  serverError: null,
  agreeTerms: null,
};
const MODAL_HEIGHT = '748px';
const MODAL_WIDTH = '657px';
const REGISTER_AJAXURL = '/DoRegister';
const TIME_SUCCESS_MESSAGE_DISPLAY = 2000; // milliseconds

export default connectWithFeedback()(
  class Register extends React.Component<ConnectedPropType, Partial<StateType>> {
    constructor(props: ConnectedPropType) {
      super(props);

      this.state = {
        username: '',
        email: '',
        password: '',
        repeatPassword: '',
        firstName: '',
        lastName: '',
        receiveNewsletter: false,
        agreeTerms: false,
        errors: InitialErrors,
        message: '',
        isSuccess: false,
        isNewsletterOpen: false,
        isTermsOpen: false,
      };
    }

    validateInput(data: RegisterViewModelType) {
      let isValid = true;
      const { username, email, password, repeatPassword, agreeTerms } = data;
      const errors = { ...InitialErrors };
      const validEmailPattern = emailPattern;
      const validPasswordPattern = passwordPattern;

      if (username === '') {
        errors.username = [translate('/Errors/InvalidUsername')];
      }

      if (email === '' || !validEmailPattern.test(email)) {
        errors.email = [translate('/Errors/InvalidEmail')];
      }

      if (!validPasswordPattern.test(password)) {
        errors.password = [translate('/Errors/InvalidPassword')];
      }

      if (password !== repeatPassword) {
        errors.repeatPassword = [translate('/Errors/PasswordsDoNotMatch')];
      }

      if (!agreeTerms) {
        errors.agreeTerms = [translate('/Account/Registration/Form/MustAggreedTerms')];
      }

      this.setState({ errors: { ...errors } });

      for (const e in errors) {
        if (errors[e] !== null) {
          isValid = false;
        }
      }

      return isValid;
    }

    onSubmit = async () => {
      this.clearErrors();
      const username = this.state.username.trim();
      const email = this.state.email.trim();
      const password = this.state.password.trim();
      const repeatPassword = this.state.repeatPassword.trim();
      const firstname = this.state.firstName ? this.state.firstName.trim() : null;
      const lastname = this.state.lastName ? this.state.lastName.trim() : null;
      const query: RegisterViewModelType = {
        username,
        email,
        password,
        repeatPassword,
        firstname,
        lastname,
        receiveNewsletter: this.state.receiveNewsletter,
        agreeTerms: this.state.agreeTerms,
      };
      const isValid = this.validateInput(query);

      if (isValid) {
        const { success, message, errors } = await postJson(REGISTER_AJAXURL, query);

        try {
          if (success) {
            this.setState({
              isSuccess: true,
              message,
            });
          } else {
            this.setState({
              isSuccess: false,
              message,
              errors: { ...InitialErrors, ...errors },
            });
            return Promise.reject(null);
          }
        } catch (error) {
          this.setState({ message: error });
          return Promise.reject(null);
        }
      } else {
        return Promise.reject(null);
      }
    };

    setInputValues = (e: any) => {
      return this.setState({ [e.target.name]: e.target.value });
    };

    onCheckBox(name: string, checked: boolean) {
      return this.setState({ [name]: checked });
    }

    clearErrors() {
      return this.setState({
        errors: { ...InitialErrors },
      });
    }

    onSuccessMessage() {
      setTimeout(() => {
        pushState(this.props.successRedirectPage || '/', { includeAppShellData: true });
      }, TIME_SUCCESS_MESSAGE_DISPLAY);
      return <SuccessMessage message={this.state.message || this.props.successMessage} />;
    }

    toggleNewsletter = () => {
      this.setState({ isNewsletterOpen: !this.state.isNewsletterOpen });
    };

    toggleTerms = () => {
      this.setState({ isTermsOpen: !this.state.isTermsOpen });
    };

    render() {
      return (
        <MainContainer>
          <AccountForm
            heading={this.props.sectionHeader}
            buttonCaption={this.props.registerButtonCaption}
            buttonAction={this.onSubmit}
            successMessage={this.state.isSuccess && this.onSuccessMessage()}
          >
            <InputContainer>
              <InputLabel>{this.props.usernameLabel}</InputLabel>
              <InputField
                required
                type="text"
                name="username"
                value={this.state.username}
                placeholder={this.props.usernamePlaceholder}
                onChange={this.setInputValues}
                invalid={!!this.state.errors.username}
              />
              <Error>{this.state.errors.username}</Error>
            </InputContainer>

            <InputContainer>
              <InputLabel>{this.props.emailLabel}</InputLabel>
              <InputField
                required
                type="email"
                name="email"
                value={this.state.email}
                placeholder={this.props.emailPlaceholder}
                onChange={this.setInputValues}
                invalid={!!this.state.errors.email}
              />
              <Error>{this.state.errors.email}</Error>
            </InputContainer>

            <PasswordVisibility
              inputLabel={this.props.passwordLabel}
              inputPlaceholder={this.props.passwordPlaceholder}
              inputValue={this.state.password}
              onChange={this.setInputValues}
              error={this.state.errors.password}
            />

            <PasswordVisibility
              name="repeatPassword"
              inputLabel={this.props.repeatPasswordLabel}
              inputPlaceholder={this.props.repeatPasswordPlaceholder}
              inputValue={this.state.repeatPassword}
              onChange={this.setInputValues}
              error={this.state.errors.repeatPassword}
            />

            <InputContainer>
              <InputLabel>{this.props.firstnameLabel}</InputLabel>
              <InputField
                type="text"
                name="firstName"
                value={this.state.firstName}
                placeholder={this.props.firstnamePlaceholder}
                onChange={this.setInputValues}
              />
            </InputContainer>

            <InputContainer>
              <InputLabel>{this.props.lastnameLabel}</InputLabel>
              <InputField
                type="text"
                name="lastName"
                value={this.state.lastName}
                placeholder={this.props.lastnamePlaceholder}
                onChange={this.setInputValues}
              />
            </InputContainer>

            <CheckboxContainer>
              <CustomCheckbox
                checked={this.state.receiveNewsletter}
                // tslint:disable-next-line:jsx-no-lambda
                onChange={(e: React.FormEvent<HTMLElement>) =>
                  this.onCheckBox('receiveNewsletter', (e.target as HTMLInputElement).checked)
                }
              />
              <div>
                {this.props.newsletterLabel}&nbsp;
                <ViewContent onClick={this.toggleNewsletter}>{this.props.newsletterLinkLabel}</ViewContent>
              </div>
              <Panel
                isOpen={this.state.isNewsletterOpen}
                onClose={this.toggleNewsletter}
                maxHeight={MODAL_HEIGHT}
                maxWidth={MODAL_WIDTH}
              >
                <Close onClick={this.toggleNewsletter}>
                  <CloseIcon
                    css={{
                      width: pixelsToUnit(40),
                      height: pixelsToUnit(40),
                    }}
                  />
                </Close>
                <Body>
                  <Container>
                    <Html html={this.props.newsletterText.html} />
                  </Container>
                </Body>
              </Panel>
            </CheckboxContainer>

            <CheckboxContainer>
              <TermsContainer>
                <CustomCheckbox
                  checked={this.state.agreeTerms}
                  // tslint:disable-next-line:jsx-no-lambda
                  onChange={(e: React.FormEvent<HTMLElement>) =>
                    this.onCheckBox('agreeTerms', (e.target as HTMLInputElement).checked)
                  }
                />
                <div>
                  {this.props.termsLabel}&nbsp;
                  <ViewContent onClick={this.toggleTerms}>{this.props.termsLinkLabel}</ViewContent>
                </div>
                <Panel
                  isOpen={this.state.isTermsOpen}
                  onClose={this.toggleTerms}
                  maxHeight={MODAL_HEIGHT}
                  maxWidth={MODAL_WIDTH}
                >
                  <Close onClick={this.toggleTerms}>
                    <CloseIcon
                      css={{
                        width: pixelsToUnit(40),
                        height: pixelsToUnit(40),
                      }}
                    />
                  </Close>
                  <Body>
                    <Container>
                      <Html html={this.props.termsText.html} />
                    </Container>
                  </Body>
                </Panel>
              </TermsContainer>
            </CheckboxContainer>
            <Error>{this.state.errors.agreeTerms}</Error>
            <ServerError>{this.state.errors.serverError}</ServerError>
            {!this.state.isSuccess && this.state.message && <ServerError>{this.state.message}</ServerError>}
          </AccountForm>
          <AccountPanel
            heading={this.props.registrationTitle}
            buttonCaption={this.props.loginButtonCaption}
            redirectLink={this.props.loginRedirectPage}
          />
        </MainContainer>
      );
    }
  },
);

const InputContainer = styled.div({
  minHeight: pixelsToUnit(112),
});

const InputLabel = styled.label({
  marginLeft: pixelsToUnit(16),
});

const InputField = styled(Input, {
  paddingLeft: pixelsToUnit(16),
});

const CheckboxContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'nowrap',
  marginBottom: pixelsToUnit(30),
});

const TermsContainer = styled.div({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  fontSize: pixelsToUnit(15),
});
const ViewContent = styled.span({
  cursor: 'pointer',
  textDecoration: 'underline',
  fontSize: pixelsToUnit(15),
});

const CustomCheckbox = styled(Checkbox, {
  marginRight: pixelsToUnit(12),
});

const Error = styled(ErrorMessage, {
  marginTop: 0,
  paddingLeft: pixelsToUnit(16),
});

const ServerError = styled(ErrorMessage, {
  margin: { x: 0, y: pixelsToUnit(20) },
});

const Body = styled.div({
  height: 'calc(100% - 160px)',
  overflow: 'auto',
  width: 'calc(100% - 80px)',
  margin: {
    y: 0,
    x: 'auto',
  },
  [minMediumMediaQuery]: {
    width: 'calc(100% - 100px)',
  },
});

const Container = styled.div({
  width: 'calc(100% - 40px)',
  [minMediumMediaQuery]: {
    width: 'calc(100% - 160px)',
    margin: {
      y: 0,
      x: 'auto',
    },
  },
});

const Close = styled.div({
  cursor: 'pointer',
  textAlign: 'right',
  margin: {
    top: pixelsToUnit(40),
    bottom: pixelsToUnit(20),
    right: pixelsToUnit(40),
  },
});
